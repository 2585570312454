<template>

  <div>
    <!-- Table Container Card -->

    <b-card
      no-body
      class="mb-0"
    >

      <div class="m-2">
        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Show</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
              @input="filterTable"
            />
            <label>entries</label>
          </b-col>

          <!-- Search -->
          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block "
                placeholder="Search..."
                @input="filterTable"
              />
              <!-- <b-button
                variant="warning"
                :to="{name:'add-job-sites'}"
              >
                <span class="text-nowrap">Add Project Site</span>
              </b-button> -->
            </div>
          </b-col>
        </b-row>

      </div>
      <b-table sticky-header
        ref="refUserListTable"
        class="position-relative mTable common_big_tbl"
        :items="dataList"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
        :class="items.length <= 10 ? 'remove_overflow' : 'add_overflow'"
      >
       <template #cell(info)="items">             
							<feather-icon
							:icon="items.detailsShowing ? 'EyeIcon' : 'EyeOffIcon'"
							@click="items.toggleDetails"
							/>
				</template>
        <template #cell(unique_id)="items">
           
            <span class="wordBreak">
              <div class="my-1" @click="items.toggleDetails">{{ items.item.unique_id | capitalize}}</div>
            </span>
         
        </template>

        <template #cell(form_name)="items">
           
            <span class="wordBreak" @click="items.toggleDetails">{{ items.item.form_name | capitalize}}</span>
         
        </template>

        <template #cell(site_name)="items">
           
            <span class="wordBreak" @click="items.toggleDetails">{{ items.item.site.site_name | capitalize}}</span>
         
        </template>



        <!-- Column: Role -->
        <template #cell(location_name)="items">
           
            <span class="wordBreak" @click="items.toggleDetails">{{ items.item.location_name | capitalize}}</span>
         
        </template>

        <template #cell(checked_by)="items">
           
            <span class="wordBreak" @click="items.toggleDetails">{{ items.item.checked_by | capitalize}}</span>
         
        </template>


        <!-- <template #cell(checked_date)="items"> -->
           
            <!-- <span>{{items.item.checked_date | moment}}</span> -->
            <!-- <span>{{sitedateTime2(items.item.checked_date,siteData.pdf_date_format,'')}}</span> -->
            
         
        <!-- </template> -->

        <!-- <template #cell(updated_at)="items"> -->
           
            <!-- <span>{{items.item.updated_at | dateTime}}</span> -->
            <!-- <span>{{sitedateTime(items.item.updated_at,siteData.pdf_date_format,siteData.pdf_time_format)}}</span> -->
         
        <!-- </template> -->

        <template #cell(actions)="items">

          <b-link
            v-if="checkPermission($route.name, 'Download')"
            v-b-tooltip.hover.v-warning
            title="Download"
            variant="outline-warning"
            @click="download(items.item.pdf)"
          >
              <feather-icon icon="DownloadIcon" class="mr-1 mediumSize"/>
              
          </b-link>
          
          <b-link
              v-b-tooltip.hover.v-warning
              title="Delete"
              variant="outline-warning"
              @click="deleteItem(items.item._id)"
            >
              <feather-icon icon="Trash2Icon" class="mr-1 mediumSize"/>
                
          </b-link>
           
        </template>

         <template #row-details="items">
                <div class="p-1 px-2 fbDetail mb-1">
                    <b-row>
                        <b-col cols="12" xl="12">
                            <table class="mt-2 mt-xl-0 w-100">
                                <tr class="mb-1">
                                    <th class="pb-50" style="width: 16%">
                                      <div class="d-flex align-items-center">
                                        
                                        <div class="ml-1">
                                          <h6 class="mb-0 mediumFonts">
                                            Checked Date
                                          </h6>
                                        </div>
                                      </div>
                                    </th>
                                    <td class="pb-50 mediumFonts" style="width: 17%;padding-top: 8px;">
                                      <span>{{sitedateTime2(items.item.checked_date,siteData.pdf_date_format,'')}}</span>
                                    </td>

                                    <th class="pb-50" style="width: 16%">
                                      <div class="d-flex align-items-center">
                                        
                                        <div class="ml-1">
                                          <h6 class="mb-0 mediumFonts">
                                            Last Updated
                                          </h6>
                                        </div>
                                      </div>
                                    </th>
                                    <td class="pb-50 mediumFonts" style="width: 17%;padding-top: 8px;">
                                      <span>{{sitedateTime(items.item.updated_at,siteData.pdf_date_format,siteData.pdf_time_format)}}</span>
                                    </td>

                                    
                                </tr>
                                
                                <tr class="mb-1">

                                    <th class="pb-50" style="width: 16%">
                                      <b-button
                                          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                                          variant="outline-secondary"
                                          @click="items.toggleDetails"
                                          size="sm"
                                          class="ml-1"
                                        >
                                          Hide Detail
                                        </b-button>
                                    </th>
                                    <td class="pb-50 mediumFonts" style="width: 17%;padding-top: 8px;">
                                        
                                    </td>
                                    <th class="pb-50" style="width: 16%">
                                    </th>
                                    <td class="pb-50 mediumFonts" style="width: 17%;padding-top: 8px;">
                                        
                                    </td>
                                    <th class="pb-50" style="width: 16%">
                                    </th>
                                    <td class="pb-50 mediumFonts" style="width: 17%;padding-top: 8px;">
                                        
                                    </td>
                                </tr>
                            </table>
                        </b-col>

                        
                    </b-row>
                </div>

            </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">Showing {{ from }} to {{ to }} of {{ totalRecords }}</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="currentPage"
              :total-rows="totalRecords"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
              @input="filterTable"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination,VBTooltip,BCardBody,BFormDatepicker
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import { ref, onUnmounted } from '@vue/composition-api'
import { POST_API } from "../../../store/actions.type"
import Ripple from 'vue-ripple-directive'
import moment from "moment";

export default {
  props: ['siteData', 'start', 'end'],
  watch: { 
    siteData: function(newVal, oldVal) { // watch it
      this.filterTable();
    },
    start: function(newVal, oldVal) { // watch it
      this.filterTable();
    },
    end: function(newVal, oldVal) { // watch it
      this.filterTable();
    },
  },
  components: {

    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BCardBody,
    vSelect,
    BFormDatepicker
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  filters: {
      moment: function(date) {
          return moment(date).format('DD MMM YYYY')
      },
    },
  data() {
    return {
       tableColumns: [
            { key: 'info', label: 'INFO', sortable: false, thStyle: { width: '1%' } },
            { key: 'unique_id', label: 'ID', sortable: true , thStyle:  {width: '25%'}},
            { key: 'form_name', label: 'Form Name', sortable: true , thStyle:  {width: '18%'}},
            { key: 'site_name', label: 'Project Site Name', sortable: false , thStyle:  {width: '18%'}},
            { key: 'location_name', label: 'Location Name', sortable: true , thStyle:  {width: '13%'}},
            { key: 'checked_by', label: 'Checked By', sortable: true , thStyle:  {width: '13%'}},
            // { key: 'checked_date', label: 'Checked Date', sortable: true , thStyle:  {width: '12%'}},
            // { key: 'updated_at', label: 'Last Updated', sortable: true , thStyle:  {width: '12%'}},
            { key: 'actions', label: 'Action', sortable: false , thStyle:  {width: '12%'}},
          ],
        
        items:[],
        isSortDirDesc: true,
        perPageOptions: [10, 20, 50, 100],
        perPage : 10,
        sortBy : 'id',
        webUrl: process.env.VUE_APP_SERVER_URL,
        totalRecords : 0,
        currentPage : 1,
        searchQuery:'',
        from:null,
        to:null,
        sites:[],
        //siteData:null,
        // calendar vars 
        //start:'',
        //end:'',
    }
  },
  methods : {
    dataList(){
      return this.$store.dispatch(POST_API, {
                   data:{
                     page: this.currentPage,
                     keyword: this.searchQuery,
                     rowsPerPage:this.perPage,
                     sortBy:this.sortBy,
                     sortDirection:this.isSortDirDesc,
                     role:this.$store.getters.currentUser.role,
                     om_sites:this.$store.getters.currentUser.om_sites,
                     site:this.siteData ? this.siteData._id :null,
                     start:moment(this.start).format('YYYY-MM-DD'),
                     end:moment(this.end).format('YYYY-MM-DD'),
                   },
                   api: '/api/all-checklists'
                })
                .then(() => {
                    if (this.$store.getters.containsErrors) {
                        this.error_message = this.$store.getters.getErrors;
                    } else {
                        this.items = this.$store.getters.getResults.data.docs;
                        this.totalRecords = this.$store.getters.getResults.data.total;
                        this.perPage = this.$store.getters.getResults.data.limit;
                        this.from = this.$store.getters.getResults.data.from
                        this.to = this.$store.getters.getResults.data.to
                        return this.items;
                    }
                });
    },
    filterTable(){
      this.$refs.refUserListTable.refresh();
    },
    variantColor(status){
      if (status == 'active') {
        return 'success';
      }else{
        return 'primary';
      }
    },
    
    download(pdf){
      window.open(pdf+'?'+ new Date(), '_blank');
    },
    deleteItem(id){
      var msg = 'Are you sure want to delete this record?';
      var message='Checklist Deleted Successfully.';

      Swal.fire({
                title: 'Please Confirm',
                text: msg,
                icon: 'warning',
                position: 'top-center',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes',
                cancelButtonText: 'No'
              })
            .then(result => {
              if (result.value) {
                  return this.$store.dispatch(POST_API, {
                    data:{
                          id: id,
                        },
                    api:"/api/delete-checklist",
                      })
                      .then(() => {
                          if (this.$store.getters.containsErrors) {
                              this.error_message = this.$store.getters.getErrors;
                              this.showAlert();
                          } else {
                            
                            Swal.fire({
                              position: 'center',
                              icon: 'success',
                              title: message,
                              showConfirmButton: false,
                              timer: 1500
                            })
                            
                            this.$refs.refUserListTable.refresh();
                          }
                      });
              }
          })
          .catch(err => {
              
          })
    },
    /*allSites(){
      return this.$store.dispatch(POST_API, {
         data:{
           role:this.$store.getters.currentUser.role,
           om_sites:this.$store.getters.currentUser.om_sites,
         },
         api: '/api/all-sites'
      })
      .then(() => {
          if (this.$store.getters.containsErrors) {
              this.error_message = this.$store.getters.getErrors;
          } else {
              this.sites = this.$store.getters.getResults.data;
              if (this.sites.length == 1 ) {
                  this.siteData = this.sites[0];

                  this.filterTable();
              }

              var obj = {
                _id:'all-site',
                site_name:'All Project Sites'
              }
              this.sites.unshift(obj);
              
              return this.sites;
          }
      });
    },*/
    
    // calendar functions
    /*updateStart(){
      this.end = moment(this.start).tz('Asia/Singapore').toDate();
      this.filterTable();
    },
    disabledDates(){
      if (this.start) {
        return moment(this.start).format('YYYY-MM-DD');
      }else{
        return moment().format('YYYY-MM-DD');
      }
    },*/
    
  },
  mounted(){
    //this.allSites();
    console.log(this.siteData)
  }
  
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
