<template>

  <div>
    <!-- Table Container Card -->
    
    <b-card
      no-body
      class="mb-0"
    >

      <div class="m-2">
        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0 mobile_tab_max_width_flex mobile_margin_bottom_1rem "
          >
            <label>Show</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
              @input="filterTable"
            />
            <label>entries</label>
          </b-col>

          <!-- Search -->
          <b-col
            cols="12"
            md="6"
            class="mobile_tab_max_width_flex all_btn_tab "
          >
            <div class="d-flex align-items-center justify-content-end mobile_tab_display_block">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1 mobile_margin_bottom_1rem "
                placeholder="Search..."
                @input="filterTable"
              />

              <b-button
                  v-if="checkPermission('custom-forms-dashboard', 'Zip Download')"
                  :disabled="selected && selected.length > 0 ? false:true"
                  variant="primary"
                  class=" "
                  @click="zipDownload()"
                >
                  <span class="text-nowrap">Zip Download</span>
                </b-button>

                <!-- <b-button
                  v-if="checkPermission('custom-forms-dashboard', 'Delete')"
                  :disabled="selected && selected.length > 0 ? false:true"
                  variant="danger"
                  class="mr-1 mobile-margin-bottom"
                  @click="multipleDelete()"
                >
                  <span class="text-nowrap">Delete</span>
                </b-button> -->
            </div>
          </b-col>
        </b-row>

      </div>
      <b-table sticky-header
        ref="refUserListTable"
        class="position-relative"
        :items="dataList"
        responsive
        :fields = "checkPermission('custom-forms-dashboard', 'Zip Download') ? tableColumns2 : tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
      >

        <template #head(checkbox)="items">
          <b-form-checkbox class="custom-control-warning" v-model="selectAll" @change="getAll"/>
        </template>

        <template #cell(checkbox)="items">
              <!-- v-if="checkPermission('custom-forms-dashboard', 'Delete') && ((items.item.level_user_status != null) && (items.item.level_user_status.role == $store.getters.currentUser.role))" -->
            <b-form-checkbox 
              v-if="checkPermission('custom-forms-dashboard', 'Delete')"
              v-model="selected" 
              :value="items.item._id" 
              class="custom-control-warning my-1"
            />
        </template>

        <!-- Column: User -->
        <template #cell(unique_id)="items">
           
            <span class="wordBreak">{{ items.item.unique_id | capitalize}}</span>
         
        </template>

        

        <template #cell(site_name)="items">
           
            <span class="wordBreak">{{ items.item.site.site_name | capitalize}}</span>
         
        </template>

        <!-- <template #cell(conducted_by)="items">
           
            <span class="wordBreak">{{ items.item.conducted_by | capitalize}}</span>
         
        </template> -->



        <!-- Column: Role -->


        <template #cell(checked_date)="items">
           
            <!-- <span>{{items.item.checked_date | dateRangePicker}}</span> -->
            <span>{{sitedateTime2(items.item.checked_date,date_format,'' )}}</span>
         
        </template>

        <!-- <template #cell(updated_at)="items">
           
            <span>{{items.item.updated_at | dateTime}}</span>
         
        </template> -->

        <template #cell(actions)="items">

              <!-- v-if="checkPermission($route.name, 'Delete')" -->
          <b-link
              v-if="checkPermission('custom-forms-dashboard', 'Delete')"
              v-b-tooltip.hover.v-warning
              title="Delete"
              variant="outline-warning"
              @click="deleteItem(items.item._id)"
            >
                <feather-icon icon="Trash2Icon" class="mr-1 mediumSize"/>
                
            </b-link>

          <b-link
            v-if="checkPermission('custom-forms-dashboard', 'Download')"
            v-b-tooltip.hover.v-warning
            title="Download"
            variant="outline-warning"
            @click="download(items.item.pdf)"
          >
              <feather-icon icon="DownloadIcon" class="mr-1 mediumSize"/>
              
          </b-link>

           
        </template>

      </b-table>
      <div class="mx-2 mb-2">
        <b-row>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">Showing {{ from }} to {{ to }} of {{ totalRecords }}</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="currentPage"
              :total-rows="totalRecords"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
              @input="filterTable"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination,VBTooltip,BCardBody,BFormDatepicker,BFormCheckbox
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import { ref, onUnmounted } from '@vue/composition-api'
import { POST_API } from "../../../store/actions.type"
import Ripple from 'vue-ripple-directive'
import moment from "moment";
import flatPickr from 'vue-flatpickr-component'

export default {
  props: ['siteData', 'start_date', 'end_date','date_format','vusers','vuser'],
  watch: { 
    siteData: function(newVal, oldVal) { // watch it
      this.filterTable();
    },
    start_date: function(newVal, oldVal) { // watch it
      this.filterTable();
    },
    end_date: function(newVal, oldVal) { // watch it
      this.filterTable();
    },
    vuser: function(newVal, oldVal) { // watch it
      this.filterTable();
    },
  },
  components: {

    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BCardBody,
    vSelect,
    flatPickr,
    BFormDatepicker,
    BFormCheckbox
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  filters: {
      moment: function(date) {
          return moment(date).format('DD MMM YYYY hh:mm A')
      },
    },
  data() {
    return {
       tableColumns: [
            { key: 'unique_id', label: 'ID', sortable: true , thStyle:  {width: '30%'}},
            { key: 'site_name', label: 'Project Site Name', sortable: false , thStyle:  {width: '30%'}},
            //{ key: 'conducted_by', label: 'Checked By', sortable: true , thStyle:  {width: '25%'}},
            { key: 'checked_date', label: 'Date', sortable: true , thStyle:  {width: '25%'}},
            /*{ key: 'updated_at', label: 'Last Updated', sortable: true , thStyle:  {width: '15%'}},*/
            { key: 'actions', label: 'Action', sortable: false , thStyle:  {width: '15%'}},
          ],
       tableColumns2: [
            { key: 'checkbox', label:'',sortable: false , thStyle:  {width: '5%'}},
            { key: 'unique_id', label: 'ID', sortable: true , thStyle:  {width: '28%'}},
            { key: 'site_name', label: 'Project Site Name', sortable: false , thStyle:  {width: '28%'}},
            { key: 'checked_date', label: 'Date', sortable: true , thStyle:  {width: '24%'}},
            { key: 'actions', label: 'Action', sortable: false , thStyle:  {width: '15%'}},
          ],
        
        items:[],
        isSortDirDesc: true,
        perPageOptions: [10, 20, 50, 100],
        perPage : 10,
        sortBy : 'id',
        webUrl: process.env.VUE_APP_SERVER_URL,
        totalRecords : 0,
        currentPage : 1,
        searchQuery:'',
        from:null,
        to:null,
        sites:[],
        // select all vars
        selectAll:false,
        selected:[],
        //siteData:null,
        //start_date: moment().tz('Asia/Singapore').toDate(),
        //end_date:moment().tz('Asia/Singapore').toDate(),
        //min_end:moment().tz('Asia/Singapore').toDate(),
    }
  },
  methods : {
    getAll(e){
      this.selected = [];        
      if (e) {
        // var role = this.$store.getters.currentUser.role;
        var selected = [];
        this.items.forEach(function(item){
          // if((item.level_user_status != null) && (item.level_user_status.role == role)){
            selected.push(item._id);
          // }
        })
        this.selected = selected;
      }
    },

    multipleDelete(){
      if (this.selected.length > 0) {
        this.decisionAlert('Are you sure you want to delete selected reports?')
        .then(result => {
          if (result.value) {
            return this.$store.dispatch(POST_API, {
              data:{
                    id: this.selected,
                  },
              api:"/api/delete-multiple-dsc-reports",
            })
            .then(() => {
                if (this.$store.getters.containsErrors) {
                    this.error_message = this.$store.getters.getErrors;
                } else {
                  this.successAlert();
                  
                  this.selected = [];
                  this.selectAll = false;
        
                  this.filterTable();
                }
            });
          }
        })
        .catch(err => {})
      }else{
        this.customAlert('Please select reports.', '', 'warning')
      }
    },

    dataList(){
      this.selectAll = false;
      this.selected  = [];

      var vuserObj = this.vusers.filter(item => { return item.key == this.vuser});

      return this.$store.dispatch(POST_API, {
                   data:{
                     page: this.currentPage,
                     keyword: this.searchQuery != '' ? this.searchQuery : '' ,
                     rowsPerPage:this.perPage,
                     sortBy:this.sortBy,
                     sortDirection:this.isSortDirDesc,
                     role:this.$store.getters.currentUser.role,
                     om_sites:this.$store.getters.currentUser.om_sites,
                     site:this.siteData != ''?this.siteData :null,
                     start:moment(this.start_date).format('YYYY-MM-DD'),
                     end:moment(this.end_date).format('YYYY-MM-DD'),
                     vuser : vuserObj.length > 0 ? vuserObj[0] : null
                   },
                   api: '/api/all-daily-checklist'
                })
                .then(() => {
                    if (this.$store.getters.containsErrors) {
                        this.error_message = this.$store.getters.getErrors;
                    } else {
                        this.items = this.$store.getters.getResults.data.docs;
                        this.totalRecords = this.$store.getters.getResults.data.total;
                        this.perPage = this.$store.getters.getResults.data.limit;
                        this.from = this.$store.getters.getResults.data.from
                        this.to = this.$store.getters.getResults.data.to
                        return this.items;
                    }
                });
    },
    filterTable(){
      this.$refs.refUserListTable.refresh();
    },
    variantColor(status){
      if (status == 'active') {
        return 'success';
      }else{
        return 'primary';
      }
    },
    download(pdf){
      window.open(pdf, '_blank');
    },

    deleteItem(id){
      var msg = 'Are you sure want to delete this record?';
      var message='Supervisor Daily Checklist Deleted Successfully.';

      Swal.fire({
                title: 'Please Confirm',
                text: msg,
                icon: 'warning',
                position: 'top-center',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes',
                cancelButtonText: 'No'
              })
            .then(result => {
              if (result.value) {
                  return this.$store.dispatch(POST_API, {
                    data:{
                          id: id,
                        },
                    api:"/api/delete-supervisor-daily-checklist",
                      })
                      .then(() => {
                          if (this.$store.getters.containsErrors) {
                              this.error_message = this.$store.getters.getErrors;
                              this.showAlert();
                          } else {
                            
                            Swal.fire({
                              position: 'center',
                              icon: 'success',
                              title: message,
                              showConfirmButton: false,
                              timer: 1500
                            })
                            
                            this.$refs.refUserListTable.refresh();
                          }
                      });
              }
          })
          .catch(err => {
              
          })
    },

    zipDownload(){
      var organization  = this.$store.getters.currentUser.organization;
      var id            = this.selected && this.selected.length > 0 ? this.selected.toString() : [];
      var start         = moment(this.start_date).format('YYYY-MM-DD');
      var end           = moment(this.end_date).format('YYYY-MM-DD');
      
      var params  = "organization="+organization+"&id="+id+"&start="+start+"&end="+end;
      var baseurl = "";

      baseurl        = process.env.VUE_APP_SERVER_URL + '/api/dsc-zip-report?' + params;
      this.selectAll = false,
      this.selected  = []
      
      window.open(baseurl,'_blank');
    },

    // zipDownload(){
    //     return this.$store.dispatch(POST_API, {
    //       data:{
    //             keyword       : this.searchQuery,
    //             sortBy        : this.sortBy,
    //             sortDirection : this.isSortDirDesc,
    //             role          : this.$store.getters.currentUser.role,
    //             om_sites      : this.$store.getters.currentUser.om_sites,
    //             site          : this.siteData != '' ? this.siteData :null,
    //             start         : moment(this.start_date).format('YYYY-MM-DD'),
    //             end           : moment(this.end_date).format('YYYY-MM-DD'),
    //             id            : this.selected,
    //           },
    //       api:"/api/dsc-zip-report",
    //     })
    //     .then(() => {
    //         if (this.$store.getters.containsErrors) {
    //             this.error_message = this.$store.getters.getErrors;
    //             this.errorAlert();
    //         } else {
    //             this.selectAll = false,
    //             this.selected  = []

    //             var data = this.$store.getters.getResults.data;

    //             if (data) {
                  
    //               var link = document.createElement("a");
    //               link.download = data.name;
    //               link.href = data.file;
    //               document.body.appendChild(link);
    //               link.click();
    //               document.body.removeChild(link);
    //               //delete link;
    //             }                   
              
    //         }
    //     });
    // }
    /*allSites(){
      return this.$store.dispatch(POST_API, {
         data:{
           role:this.$store.getters.currentUser.role,
           om_sites:this.$store.getters.currentUser.om_sites,
         },
         api: '/api/all-sites'
      })
      .then(() => {
          if (this.$store.getters.containsErrors) {
              this.error_message = this.$store.getters.getErrors;
          } else {
              this.sites = this.$store.getters.getResults.data;
              if (this.sites.length == 1 ) {
                  this.siteData = this.sites[0];

                  this.filterTable();
              }

              var obj = {
                _id:'all-site',
                site_name:'All Project Sites'
              }
              this.sites.unshift(obj);
              
              return this.sites;
          }
      });
    },
    updateDate(){
      this.end_date = moment(this.start_date).toDate();
      this.min_end = moment(this.start_date).toDate();

      this.filterTable();
    },*/
  },
  mounted(){
    //this.allSites();
  }
  
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
